import { useCallback, useMemo, useState } from 'react';
import { isEmpty, reject } from 'rambda';

import { CustomerService } from 'App/api';
import { useApiQuery, useQueryParams } from 'hooks';
import {
  CustomersRequest,
  DEFAULT_PAGE_SIZE,
  getFullName,
  getSelectOptions,
  hasPages,
  normalizeCustomerList,
} from 'shared';

type RequestFilter = { search_string: string };

export const useCustomers = ({
  isSelectAsync = false,
}: {
  isSelectAsync?: boolean;
}) => {
  const [isCycleOnHold, setIsCycleOnHold] = useState<boolean>(false);
  const [pageSelectAsync, setPageSelectAsync] = useState(1);
  const [searchStringSelectAsync, setSearchStringSelectAsync] = useState('');

  const { searchParams, handleSetSearchParams } = useQueryParams({
    page: '1',
    search_string: '',
  });

  const page = Number(searchParams.get('page') ?? 1);
  const search_string = searchParams.get('search_string') ?? '';

  const params: CustomersRequest = useMemo(() => {
    return {
      page: isSelectAsync ? pageSelectAsync : Number(page) || 1,
      search_string: isSelectAsync ? searchStringSelectAsync : search_string,
      only_with_cycle_on_hold: isCycleOnHold,
    };
  }, [
    isSelectAsync,
    pageSelectAsync,
    page,
    searchStringSelectAsync,
    search_string,
    isCycleOnHold,
  ]);

  const key = JSON.stringify(params);

  const { data, isLoading } = useApiQuery(
    ['getCustomers', key],
    () =>
      CustomerService.getCollection(
        reject(isEmpty, params as Record<string, unknown>),
      ),
    { ...(isSelectAsync ? { cacheTime: 0 } : {}) },
  );

  const output = useMemo(() => {
    if (!data) return [];

    return normalizeCustomerList(data.items);
  }, [data]);

  const updateFilters = useCallback(
    (data: RequestFilter) => {
      handleSetSearchParams({ page: '1', ...data });
    },
    [handleSetSearchParams],
  );

  const handleSetSearchString = (value: string) => {
    if (value) {
      setPageSelectAsync(1);
      setSearchStringSelectAsync(value);
    } else {
      setSearchStringSelectAsync('');
    }
  };

  const userOptions = useMemo(
    () =>
      getSelectOptions(
        output.map(({ id, first_name, last_name }) => ({
          id,
          name: getFullName({ first_name, last_name }),
        })),
      ),
    [output],
  );

  return {
    page,
    search_string,
    output,
    userOptions,
    totalRecords: data?.total ?? 0,
    isLoading,
    isCycleOnHold,
    updateFilters,
    handleSetSearchParams,
    setIsCycleOnHold,
    setPageSelectAsync,
    handleSetSearchString,
    hasMore: hasPages({
      total: data?.total,
      defaultSize: DEFAULT_PAGE_SIZE,
      currentPage: Number(pageSelectAsync) || 1,
    }),
  };
};
