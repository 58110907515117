import { AccountGroup, AddressDto, TireAddonData } from '../customer';
import { InventoryType } from '../inventory';
import { ShipmentCycleShortItem } from './shipmentCycle';
import { DeliveryMethod, SelectOption } from '../common';
import { Checkout } from '../orders';
import { Modifier } from '../culinary';

export type LinkOrderOptions = {
  items?: {
    label: string;
    link: string;
    total: string;
  }[];
  grandTotal?: string;
};

export enum RefundType {
  FULL = 'full',
  ITEM_BASED = 'item_based',
  TRANSACTION_BASED = 'transaction_based',
}

export type RefundPayload = {
  refund_type: RefundType;
  payment?: {
    payment_id: string;
    items: RefundItemRequest[];
  };
};

export interface RefundItem
  extends Pick<InventoryDetail, 'sku_name' | 'price' | 'total'> {
  item_id?: string;
  item_quantity: number;
  initial_quantity: number;
  isSelected: boolean;
}

type StringDateNull = string | Date | null;

export enum SalesOrderPaymentStatus {
  PAID = 'paid',
  PROCESSING_PAYMENT = 'processing_payment',
  PARTIALLY_PAID = 'partially_paid',
  UNPAID = 'unpaid',
  VOID = 'void',
  CANCEL = 'cancel',
  ERROR = 'error',
  REFUND = 'refund',
  PARTIALLY_REFUND = 'partially_refund',
  THREE_D_SECURE_CONFIRMATION = 'three_d_secure_confirmation',
}

export const orderPaymentStatusLabels = {
  [SalesOrderPaymentStatus.PAID]: 'Paid',
  [SalesOrderPaymentStatus.PROCESSING_PAYMENT]: 'Processing Payment',
  [SalesOrderPaymentStatus.PARTIALLY_PAID]: 'Partially Paid',
  [SalesOrderPaymentStatus.UNPAID]: 'Unpaid',
  [SalesOrderPaymentStatus.VOID]: 'Void',
  [SalesOrderPaymentStatus.CANCEL]: 'Cancel',
  [SalesOrderPaymentStatus.ERROR]: 'Error',
  [SalesOrderPaymentStatus.REFUND]: 'Refunded',
  [SalesOrderPaymentStatus.PARTIALLY_REFUND]: 'Partially Refund',
  [SalesOrderPaymentStatus.THREE_D_SECURE_CONFIRMATION]:
    'Three D Secure confirmation',
};

export enum ServiceClass {
  wine_club = 'wine_club',
  regular = 'regular',
}

export type RefundItemRequest = {
  item_id?: string;
  item_quantity: number;
};

export type RefundedItem = {
  item_id: string;
  discount: number;
  sub_total: number;
  price: number;
  product_name: string;
  quantity: number;
  chosen_quantity: number;
  refunded_quantity: number;
  membership_discount: number;
  isSelected?: boolean;
};

export type RefundedItemCost = {
  items: RefundedItem[];
  items_full_price: number;
  shipping_cost: number;
  shipping_tax: number;
  total_taxes: number;
  total_fees: number;
  tips: number;
  total_promocode_discount: number;
  sub_total: number;
};

export enum OrderType {
  pos = 'pos',
  shipment = 'shipment',
  cycle = 'cycle',
}

export const labelOrder = {
  [OrderType.pos]: 'POS',
  [OrderType.shipment]: 'SHIPMENT',
  [OrderType.cycle]: 'ALICE',
};

export type ParamsShipmentSalesOrder = {
  service_class?: ServiceClass;
};

export enum SalesOrderComplianceStatus {
  COMPLIANT = 'compliant',
  NOT_COMPLIANT = 'not_compliant',
  ERROR = 'error',
}
export enum SalesOrderStatus {
  OPEN = 'open',
  PROCESSING = 'processing',
  WAITING_SHIPPING = 'waiting_shipping',
  WAITING_PICKUP = 'waiting_pickup',
  SHIPPING = 'shipping',
  CLOSED = 'closed',
  PENDING_SHIPPING = 'pending_shipping',
  PENDING_BILLING = 'pending_billing',
  CANCEL = 'cancel',
  SKIPPED = 'skipped',
  PENDING_DELIVERY_METHOD_CHANGE = 'pending_delivery_method_change',
}

export enum SalesOrderItemChannel {
  EXPERIENCES = 'experiences',
  WINE_CLUB = 'wine_club',
  REGULAR_SALES = 'regular_sales',
}

export const salesOrderStatusLabels: Record<SalesOrderStatus, string> = {
  [SalesOrderStatus.OPEN]: 'Open',
  [SalesOrderStatus.PROCESSING]: 'Processing',
  [SalesOrderStatus.WAITING_SHIPPING]: 'Waiting Shipping',
  [SalesOrderStatus.WAITING_PICKUP]: 'Waiting Pickup',
  [SalesOrderStatus.SHIPPING]: 'Shipping',
  [SalesOrderStatus.CLOSED]: 'Closed',
  [SalesOrderStatus.PENDING_SHIPPING]: 'Pending Shipping',
  [SalesOrderStatus.PENDING_BILLING]: 'Pending Billing',
  [SalesOrderStatus.CANCEL]: 'Canceled',
  [SalesOrderStatus.SKIPPED]: 'Skipped',
  [SalesOrderStatus.PENDING_DELIVERY_METHOD_CHANGE]:
    'Pending Delivery Method Change',
};

export const salesOrderChannelLabels: Record<SalesOrderItemChannel, string> = {
  [SalesOrderItemChannel.EXPERIENCES]: 'Experiences',
  [SalesOrderItemChannel.WINE_CLUB]: 'Wine Club',
  [SalesOrderItemChannel.REGULAR_SALES]: 'Regular Sales',
};

export enum SalesOrderSource {
  ALICE = 'alice',
  POS = 'pos',
  ECOMMERCE = 'ecommerce',
}

export const salesOrderSourceTitle = {
  [SalesOrderSource.ALICE]: 'Alice',
  [SalesOrderSource.POS]: 'Pos',
  [SalesOrderSource.ECOMMERCE]: 'Ecommerce',
};
export enum SupplyType {
  FULFILLMENT = 'fulfillment',
  PICKUP = 'pickup',
  IN_HOUSE = 'in_house',
}

export type FreightCompany = 'ups' | 'fedex';
export type FreightMethod = 'air' | 'ground';

export const supplyTypeLabels: Record<SupplyType, string> = {
  [SupplyType.FULFILLMENT]: 'Fulfillment',
  [SupplyType.PICKUP]: 'Pickup',
  [SupplyType.IN_HOUSE]: 'In House',
};

export interface AdvisorSchema {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  is_system: boolean;
}

interface CustomerWithTierSchema {
  id: string;
  number: string;
  first_name: string;
  last_name: string;
  main_tier: TireAddonData;
  add_on_tier: TireAddonData | null;
  email: string;
  phone_number: string;
  account_group: AccountGroup;
}

export interface InventoryDetail {
  key?: string;
  id?: string;
  quantity: number;
  sku_id: string;
  sku_name: string;
  price: number;
  discount: number;
  sku_inventory_type?: InventoryType;
  total: number;
  payment_status?: SalesOrderPaymentStatus;
  refunded_quantity?: number;
  promocode_data?: { id: string; name: string };
}

export type CorrectionOrder = {
  id: string;
  fee_correction: number;
  shipping_correction: number;
  tax_correction: number;
  total_due_correction: number;
};

export type RecalculateRequest = {
  delivery_method: DeliveryMethod;
  customer_shipping_address_id?: string;
};

export type PayRecalculateRequest = {
  delivery_method: DeliveryMethod;
  supply_type: SupplyType | null;
  deplete_location_id: string | null;
  deplete_inventory_location_id: string | null;
  customer_shipping_address_id: string | null;
  freight_company: FreightCompany | null;
  freight_method: FreightMethod | null;
  shipping_date: StringDateNull;
  customer_card_id: string | null;
  source: SalesOrderSource;
};

export type RecalculateAndPayRequest = {
  delivery_method: DeliveryMethod;
  order_ids: string[];
};

export interface OrderDto {
  id: string;
  number: number;
  shipment_cycle: ShipmentCycleShortItem;
  date: Date;
  source: SalesOrderSource;
  processed_in: SalesOrderSource | null;
  total: number;
  customer: CustomerWithTierSchema;
  payment_status: SalesOrderPaymentStatus;
  status: SalesOrderStatus;
  created_at: string;
  fully_paid_at: string;
  channel: SalesOrderItemChannel;
  items: InventoryDetail[];
  delivery_method: DeliveryMethod;
  pick_up_deadline?: string;
  supply_type: SupplyType;
  freight_company?: FreightCompany;
  freight_method?: FreightMethod;
  shipping_cost?: number;
  shipping_date?: string;
  tracking_number?: string;
  billing_datetime?: string;
  re_billing_datetime?: string;
  has_shipping_data_diff?: boolean;
  updated_by: AdvisorSchema;
  compliance_status: SalesOrderComplianceStatus;
  service_class: ServiceClass;
  corrections: CorrectionOrder[];
  calculated_correction?: CorrectionOrder;
  is_zero_shipping_tax_fallback_triggered: boolean;
  deplete_location?: {
    id: string;
    name: string;
    type: SupplyType;
  };
  deplete_inventory_location?: {
    id: string;
    name: string;
  };
  signature_url: string;
  shipping_address?: {
    first_name: string;
    last_name: string;
    address_line_1: string;
    address_line_2: string;
    country: string;
    state: string;
    city: string;
    business: string;
    zip_code: string;
  };
  card: {
    last4: string;
    brand: string;
    stripe_card_id: string;
  };
  checkout: Checkout | null;
}

export interface CycleOrderPayload {
  number?: number;
  createdAt?: string | null;
  customer?: CustomerWithTierSchema;
  processedIn?: SalesOrderSource | null;
  shipmentCycle?: ShipmentCycleShortItem;
  customer_id?: string;
  status: SalesOrderStatus;
  items: InventoryDetail[];
  delivery_method: DeliveryMethod;
  channel: SalesOrderItemChannel;
  supply_type?: SupplyType;
  payment_status: SalesOrderPaymentStatus;
  customer_shipping_address_id?: string | null;
  deplete_location_id: string | null;
  deplete_inventory_location_id: string | null;
  freight_company?: FreightCompany | null;
  freight_method?: FreightMethod | null;
  shipping_date?: StringDateNull;
  tracking_number?: string | null;
  pick_up_deadline?: StringDateNull;
  billing_datetime?: StringDateNull;
  re_billing_datetime?: StringDateNull;
  shipment_cycle_id?: string;
  customer_card_id: string | null;
  updated_by_id: string;
  checkout: Checkout | null;
  is_skipped?: boolean;
  compliance_status: SalesOrderComplianceStatus;
  defaultWineAdvisor: SelectOption;
  promocode_id: string | null;
  defaultDiscount?: {
    value: string;
    label: string;
  };
  defaultCustomer?: {
    value: string;
    label: string;
  };
}

export interface CustomerCycleOrder {
  id: string;
  number: string;
  type: string;
  status: string;
  payment_status: SalesOrderPaymentStatus;
  customer: {
    id: string;
    number: string;
    first_name: string;
    last_name: string;
  };
}

export interface CustomerOrderBody {
  address?: AddressDto;
  location?: {
    id?: string;
  };
  card?: {
    stripe_card_id?: string;
    brand?: string;
    last4?: string;
  };
}

export interface WalletSelectOption extends SelectOption {
  stripe_card_id?: string;
}

export interface CustomerSkippedOrder extends CustomerCycleOrder {
  shipment_cycle: ShipmentCycleShortItem;
  closed_at: string;
  updated_by: AdvisorSchema;
}

export interface CustomerSkippedOrders {
  items: CustomerSkippedOrder[];
  total: number;
}

export type OrderProductsTable = {
  id: string | undefined;
  quantity: string | number;
  refunded_quantity?: number;
  modifiers?: Modifier[];
  product_name: string;
  retail_price: string;
  discount_string: string;
  discounted_price: string;
  total_price: string;
};

export enum PaymentStatus {
  success = 'success',
  pending = 'pending',
  failed = 'failed',
  refunded = 'refunded',
  partially_refunded = 'partially_refunded',
}

export const paymentStatusValue = {
  [PaymentStatus.failed]: 'Error',
  [PaymentStatus.pending]: 'Pending',
};

export type ClubMemberShip = {
  bottleQty: number;
  text: string;
};
