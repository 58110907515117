import request from '../../request';
import {
  PaginatedRequest,
  PaginatedResponse,
  OrderDto,
  CycleOrderPayload,
  CustomerOrderBody,
  SalesOrderPaymentStatus,
  CustomerCycleOrder,
  CustomerSkippedOrders,
  ParamsShipmentSalesOrder,
  RecalculateRequest,
  PayRecalculateRequest,
  RecalculateAndPayRequest,
  SalesOrderSource,
} from 'shared';
import {
  SHIPMENT_SALES_ORDERS_PATH,
  SHIPMENT_CYCLE_SALES_ORDERS_PATH,
  SHIPMENT_SALES_ORDER_COMPLIANCE_HISTORY_PATH,
} from '../../endpoints';

const { REACT_APP_API_URL } = process.env;

export default class ShipmentSalesOrdersService {
  static readonly getCollection = (
    params: PaginatedRequest & ParamsShipmentSalesOrder,
  ) => {
    return request<PaginatedResponse<OrderDto>>({
      url: SHIPMENT_SALES_ORDERS_PATH,
      params,
      method: 'get',
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<OrderDto>({
      url: `${SHIPMENT_SALES_ORDERS_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly getComplianceHistory = ({
    params,
  }: {
    params: PaginatedRequest;
  }) => {
    return request<PaginatedResponse<unknown>>({
      url: `${SHIPMENT_SALES_ORDER_COMPLIANCE_HISTORY_PATH}`,
      params,
      method: 'get',
    });
  };

  static readonly create = (data: CycleOrderPayload) => {
    return request<OrderDto>({
      url: SHIPMENT_SALES_ORDERS_PATH,
      method: 'post',
      data,
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id?: string;
    data: CycleOrderPayload;
  }) => {
    return request<OrderDto>({
      url: `${SHIPMENT_SALES_ORDERS_PATH}/${id}`,
      method: 'put',
      data,
    });
  };

  static readonly updateV2 = ({
    id,
    data,
  }: {
    id?: string;
    data: CycleOrderPayload;
  }) => {
    return request<OrderDto>({
      baseURL: `${REACT_APP_API_URL}/v2`,
      url: `${SHIPMENT_SALES_ORDERS_PATH}/${id}`,
      method: 'put',
      data,
    });
  };

  static readonly resendToShipcompliant = ({ id }: { id?: string }) => {
    return request({
      url: `${SHIPMENT_SALES_ORDERS_PATH}/${id}/ship`,
      method: 'post',
    });
  };

  static readonly recalculatePaidOrder = ({
    id,
    data,
  }: {
    id?: string;
    data: RecalculateRequest;
  }) => {
    return request<OrderDto>({
      url: `${SHIPMENT_SALES_ORDERS_PATH}/${id}/recalculate_paid_order`,
      method: 'post',
      data,
    });
  };

  static readonly recalculateAndPaidOrder = (
    data: RecalculateAndPayRequest,
  ) => {
    return request({
      url: `${SHIPMENT_SALES_ORDERS_PATH}/recalculate_and_pay_orders`,
      method: 'post',
      data: { ...data, source: SalesOrderSource.ALICE },
    });
  };

  static readonly payRecalculateOrder = ({
    id,
    data,
  }: {
    id?: string;
    data: PayRecalculateRequest;
  }) => {
    return request<OrderDto>({
      url: `${SHIPMENT_SALES_ORDERS_PATH}/${id}/pay_recalculated_order`,
      method: 'post',
      data,
    });
  };

  static readonly confirmAndPay = ({ id }: { id?: string }) => {
    return request({
      url: `${SHIPMENT_SALES_ORDERS_PATH}/${id}/confirm-and-pay`,
      method: 'post',
    });
  };

  static readonly getCustomerOrders = ({
    data,
    params,
    customer_id,
  }: {
    data?: CustomerOrderBody;
    params?: { payment_statuses?: SalesOrderPaymentStatus[] };
    customer_id?: string;
  }) => {
    return request<PaginatedResponse<CustomerCycleOrder>>({
      url: `${SHIPMENT_SALES_ORDERS_PATH}/all/${customer_id}`,
      method: 'post',
      params,
      data,
    });
  };

  static readonly cancel = ({ id }: { id?: string }) => {
    return request({
      url: `${SHIPMENT_SALES_ORDERS_PATH}/${id}/cancel`,
      method: 'post',
    });
  };

  static readonly skip = ({ id }: { id?: string }) => {
    return request({
      url: `${SHIPMENT_CYCLE_SALES_ORDERS_PATH}/${id}/skip`,
      method: 'post',
    });
  };

  static readonly getSkippedCustomerOrders = ({
    customer_id,
  }: {
    customer_id?: string;
    params?: { page: number };
  }) => {
    return request<CustomerSkippedOrders>({
      url: `${SHIPMENT_CYCLE_SALES_ORDERS_PATH}/skipped/${customer_id}`,
      method: 'get',
    });
  };
}
