import { ReactNode, useCallback } from 'react';
import { Grid, Select, Text, Switch } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DatePicker } from '@mantine/dates';

import {
  CycleOrderPayload,
  SWITCH_GRID_TOP_OFFSET,
  supplyTypeOptions,
  SupplyType,
  DATE_FORMAT_SLASH,
  SalesOrderPaymentStatus,
  SalesOrderStatus,
  OrderDto,
  DeliveryMethod,
  RecalculateRequest,
} from 'shared';
import { FreightData } from '../freightData';
import { useDeliveryData } from 'hooks';

type PropType = {
  form: UseFormReturnType<CycleOrderPayload>;
  customerId?: string;
  defaultAddress?: string;
  shippingCost?: number;
  anonymus?: ReactNode;
  setRecalculateOrder?: React.Dispatch<React.SetStateAction<OrderDto | null>>;
  handleRecalculatePaidOrder?: (data: RecalculateRequest) => void;
};

export const DeliveryData = ({
  form,
  customerId,
  defaultAddress,
  shippingCost,
  anonymus,
  setRecalculateOrder = () => null,
  handleRecalculatePaidOrder = () => null,
}: PropType) => {
  const { setValues, values } = form;
  const {
    delivery_method,
    deplete_location_id,
    supply_type,
    payment_status,
    status,
  } = values;
  const { addressOptions, inventoryLocationOptions, primaryLocationOptions } =
    useDeliveryData({
      primaryLocationId: deplete_location_id ?? undefined,
      customerId,
      supplyType: supply_type,
    });

  const isPickup = delivery_method === DeliveryMethod.PICKUP;

  const isPaid = payment_status === SalesOrderPaymentStatus.PAID;
  const isDeliveryMethodChange =
    status === SalesOrderStatus.PENDING_DELIVERY_METHOD_CHANGE;

  const isPickupToShipment =
    isPaid && status === SalesOrderStatus.WAITING_PICKUP;

  const isShipmentToPickup = isPaid && status === SalesOrderStatus.SHIPPING;

  const shippingAddress = defaultAddress && `${defaultAddress} (Saved)`;

  const shippingAddressOptions = shippingAddress
    ? [{ label: shippingAddress, value: 'null' }, ...addressOptions].filter(
        (i) =>
          i.label?.trim() !== shippingAddress?.replace('(Saved)', '').trim(),
      )
    : addressOptions;

  const changeSupplyType = useCallback(
    (val: SupplyType) => {
      if (
        (val === SupplyType.FULFILLMENT &&
          values.supply_type !== SupplyType.FULFILLMENT) ||
        (val !== SupplyType.FULFILLMENT &&
          values.supply_type === SupplyType.FULFILLMENT)
      ) {
        setValues({
          supply_type: val,
          deplete_location_id: '',
          deplete_inventory_location_id: '',
        });
      }
    },
    [setValues, values.supply_type],
  );

  const resetDeplete = () => {
    form.setFieldValue('deplete_location_id', null);
    form.setFieldValue('deplete_inventory_location_id', null);
  };

  const shippingMethod = () => {
    if (isPickup) {
      return (
        <Select
          required={isPickup}
          label="Pickup Location"
          value={form.values.deplete_location_id}
          data={[...primaryLocationOptions]}
          {...form.getInputProps('deplete_location_id')}
        />
      );
    } else {
      return anonymus ? (
        <>
          <Text weight={600} mb="sm">
            Shipping Address
          </Text>
          <Text>{shippingAddressOptions[0].label}</Text>
        </>
      ) : (
        <Select
          required={!isPickup}
          label="Shipping Address"
          data={shippingAddressOptions}
          {...form.getInputProps('customer_shipping_address_id')}
          onChange={(value) => {
            if ((isPickupToShipment || isDeliveryMethodChange) && value) {
              handleRecalculatePaidOrder({
                delivery_method: DeliveryMethod.SHIPPING,
                customer_shipping_address_id: value,
              });
            }
            form.setFieldValue('customer_shipping_address_id', value);
          }}
        />
      );
    }
  };

  return (
    <Grid gutter="xl">
      <Grid.Col>
        <Text size="lg" weight={600}>
          Delivery Information
        </Text>
      </Grid.Col>
      <Grid.Col span={4} pt={SWITCH_GRID_TOP_OFFSET}>
        <Grid gutter="xl">
          <Grid.Col span={6}>
            <Switch
              label="Shipment"
              labelPosition="right"
              checked={!isPickup}
              sx={{ fontWeight: 600 }}
              onChange={() => {
                form.setFieldValue('delivery_method', DeliveryMethod.SHIPPING);
                form.setFieldValue('supply_type', SupplyType.FULFILLMENT);
                if (isPickupToShipment) {
                  handleRecalculatePaidOrder({
                    delivery_method: DeliveryMethod.SHIPPING,
                  });
                } else {
                  setRecalculateOrder(null);
                }

                resetDeplete();
              }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Switch
              label="Pickup"
              labelPosition="right"
              checked={isPickup}
              sx={{ fontWeight: 600 }}
              onChange={() => {
                form.setFieldValue('delivery_method', DeliveryMethod.PICKUP);
                form.setFieldValue('supply_type', SupplyType.PICKUP);

                if (isShipmentToPickup) {
                  handleRecalculatePaidOrder({
                    delivery_method: DeliveryMethod.PICKUP,
                  });
                } else {
                  setRecalculateOrder(null);
                }

                resetDeplete();
              }}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={4}>{shippingMethod()}</Grid.Col>
      {anonymus}
      <Grid.Col span={4} />
      <Grid.Col span={4}>
        <Select
          required
          label="Supply Type"
          data={supplyTypeOptions(isPickup)}
          {...form.getInputProps('supply_type')}
          onChange={changeSupplyType}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          required
          label="Deplete from Primary Location"
          data={[...primaryLocationOptions]}
          {...form.getInputProps('deplete_location_id')}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          required={!isPickup}
          clearable={isPickup}
          label="Deplete from Inventory Location"
          data={inventoryLocationOptions}
          {...form.getInputProps('deplete_inventory_location_id')}
        />
      </Grid.Col>

      {isPickup && (
        <Grid.Col span={4}>
          <DatePicker
            required={isPickup}
            inputFormat={DATE_FORMAT_SLASH}
            placeholder="mm/dd/yyyy"
            label="Pickup Deadline"
            {...form.getInputProps('pick_up_deadline')}
          />
        </Grid.Col>
      )}
      {!isPickup && (
        <Grid.Col>
          <FreightData form={form} shippingCost={shippingCost} />
        </Grid.Col>
      )}
    </Grid>
  );
};
