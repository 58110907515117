import {
  CustomerDto,
  CycleOrderPayload,
  DATE_FORMAT_SLASH,
  DeliveryMethod,
  formatDate,
  getFullName,
  SalesOrderItemChannel,
  SalesOrderSource,
  SupplyType,
} from 'shared';

const mockInitialValues = {
  payment_status: null,
  customer_id: '',
  status: null,
  number: 'Will be created after saving',
  created_at: formatDate(new Date(), DATE_FORMAT_SLASH),
  processed_in: SalesOrderSource.ALICE,
  items: [
    {
      key: 1,
      price: 0,
      discount: 0,
      quantity: 0,
    },
  ],
  channel: SalesOrderItemChannel.REGULAR_SALES,
  updated_by_id: '',
  delivery_method: DeliveryMethod.SHIPPING,
  supply_type: SupplyType.FULFILLMENT,
  deplete_location_id: '',
  deplete_inventory_location_id: '',
  pick_up_deadline: null,
  pick_up_location_id: null,
  customer_shipping_address_id: '',
  freight_company: 'ups',
  freight_method: 'ground',
  shipping_date: null,
  tracking_number: '',
  customer_card_id: '',
} as unknown as CycleOrderPayload;

export const getInitialValue = ({ state }: { state?: CustomerDto }) => {
  if (state) {
    const {
      id,
      addresses,
      wallets,
      profile: { first_name, last_name },
      club_membership: { delivery_method, pickup },
    } = state;

    const fullName = getFullName({ first_name, last_name });
    const customer_id = id;
    const customer_shipping_address_id = addresses.find(
      ({ is_default }) => is_default,
    )?.id;
    const customer_card_id =
      wallets.find(({ is_default }) => is_default)?.id ?? '';

    const isPickup = delivery_method === DeliveryMethod.PICKUP;

    return {
      ...mockInitialValues,
      customer_id,
      customer_shipping_address_id,
      customer_card_id,
      delivery_method,
      supply_type: isPickup ? SupplyType.PICKUP : SupplyType.FULFILLMENT,
      deplete_location_id: pickup?.location.id ?? '',
      defaultCustomer: { value: id, label: fullName },
    };
  }

  return mockInitialValues;
};
